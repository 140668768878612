import { getUniversalHelpers } from "../../helpers";
import { Org, OrgId, OrgTeamTag, OrgTeamTagId } from "@ollie-sports/models";
import { firestoreLiftDocsSubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function orgTeamTag__client__getMultiTeamTagsSubscription(p: { orgTeamTagIds: OrgTeamTagId[] }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return firestoreLiftDocsSubToBifrostSub(h.OrgTeamTag.docsSubscription(p.orgTeamTagIds));
}

// i18n certified - complete
