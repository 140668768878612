import { getUniversalHelpers } from "../../helpers";
import { AccountId, CONVERSATION_TYPES, Org, OrgId, OrgTeamTag, OrgTeamTagId, __ConversationOrg } from "@ollie-sports/models";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";
import { BatchTask } from "@ollie-sports/firebase";
import { getBatchTasksToUpdateTagDependenciesOnDeletedTag } from "../../utils/org-team-tag-utils";
import _ from "lodash";

export async function orgTeamTag__server__deleteTeamTag(p: { orgTeamTag: OrgTeamTag; selfAccountId: AccountId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const org = await h.Org.getDoc(p.orgTeamTag.orgId);

  if (!org) {
    throw new Error(`Can't find the org associated with the org ID: ${p.orgTeamTag.orgId}`);
  }

  if (!org.accounts[p.selfAccountId]?.exists) {
    throw new Error(
      `This account (${p.selfAccountId}) does not have permission to delete a tag for this org (${p.orgTeamTag.orgId})`
    );
  }

  let batchTasks: BatchTask[] = [];
  batchTasks.push(await h.OrgTeamTag.delete({ id: p.orgTeamTag.id }, { returnBatchTask: true }));

  const updateDependencyTasks = await getBatchTasksToUpdateTagDependenciesOnDeletedTag({
    orgTeamTagId: p.orgTeamTag.id
  });
  batchTasks = [...batchTasks, ...updateDependencyTasks];

  await Promise.all(
    _.chunk(batchTasks, 500).map(async chunk => {
      return await h._BatchRunner.executeBatch(chunk);
    })
  );
}

orgTeamTag__server__deleteTeamTag.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};

// i18n certified - complete
