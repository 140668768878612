import { getUniversalHelpers } from "../../helpers";
import { Org, OrgId, OrgTeamTag } from "@ollie-sports/models";

export async function orgTeamTag__client__getTeamTagsForOrg(p: { orgId: OrgId }): Promise<OrgTeamTag[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return (
    await h.OrgTeamTag.query({
      where: [{ orgId: ["==", p.orgId] }]
    })
  ).docs;
}

// i18n certified - complete
