import { getUniversalHelpers } from "../../helpers";
import { Org, OrgId, OrgTeamTag } from "@ollie-sports/models";
import { BifrostSubscription, createBifrostSubscription } from "@ollie-sports/react-bifrost";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function orgTeamTag__client__getTeamTagsForOrgSubscription(p: { orgId: OrgId }): BifrostSubscription<OrgTeamTag[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return firestoreLiftQuerySubToBifrostSub(
    h.OrgTeamTag.querySubscription({
      where: [
        {
          orgId: ["==", p.orgId]
        }
      ]
    })
  );
}

// i18n certified - complete
