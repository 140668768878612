import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";
import { AccountId, OrgId, OrgTeamTag } from "@ollie-sports/models";
import moment from "moment";

export async function orgTeamTag__server__createTag(p: { orgId: OrgId; tag: string; selfAccountId: AccountId }) {
  // SERVER_ONLY_TOGGLE

  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const org = await h.Org.getDoc(p.orgId);

  if (!org) {
    throw new Error(`Can't find the org associated with the org ID: ${p.orgId}`);
  }

  if (!org.accounts[p.selfAccountId]?.exists) {
    throw new Error(`This account (${p.selfAccountId}) does not have permission to create a tag for this org (${p.orgId})`);
  }

  const id = await h.OrgTeamTag.generateId();
  const now = Date.now();

  const newTag: OrgTeamTag = {
    id,
    orgId: p.orgId,
    tag: p.tag,
    createdAtMS: now,
    updatedAtMS: now
  };

  await h.OrgTeamTag.add({
    doc: newTag
  });

  return newTag;

  // SERVER_ONLY_TOGGLE
}

orgTeamTag__server__createTag.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};
// i18n certified - complete
