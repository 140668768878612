import { SimpleQuery } from "@ollie-sports/firebase-lift";
import { getUniversalHelpers } from "../../helpers";
import { Org, OrgId, OrgTeamTag, OrgTeamTagId } from "@ollie-sports/models";
import _ from "lodash";

export async function orgTeamTag__client__getOrgTeamTagsForOrgs(p: { orgIds: OrgId[] }): Promise<OrgTeamTag[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const orgIdChunks = _.chunk(p.orgIds, 10);
  const orgTeamTags = (
    await h.OrgTeamTag.multiQuery({
      queries: p.orgIds.map(orgId => {
        let q: SimpleQuery<OrgTeamTag> = {
          where: [{ orgId: ["==", orgId] }]
        };
        return q;
      })
    })
  ).docs;

  return orgTeamTags;
}

// i18n certified - complete
