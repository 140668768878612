import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";
import { AccountId, OrgId, OrgTeamTagId, TeamId } from "@ollie-sports/models";
import { BatchTask } from "@ollie-sports/firebase";
import _ from "lodash";

export async function orgTeamTag__server__bulkUpdateTag(p: {
  orgId: OrgId;
  selfAccountId: AccountId;
  teamIdsToAdd: TeamId[];
  teamIdsToRemove: TeamId[];
  orgTeamTagId: OrgTeamTagId;
  newTag?: string;
}) {
  // SERVER_ONLY_TOGGLE

  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let batchTasks: BatchTask[] = [];

  const [org, orgTeamTag] = await Promise.all([h.Org.getDoc(p.orgId), h.OrgTeamTag.getDoc(p.orgTeamTagId)]);

  if (!org) {
    throw new Error(`Can't find the org associated with the org ID: ${p.orgId}`);
  }

  if (!org.accounts[p.selfAccountId]?.exists) {
    throw new Error(`This account (${p.selfAccountId}) does not have permission to edit a tag for this org (${p.orgId})`);
  }

  if (!orgTeamTag) {
    throw new Error(`Can't find the team tag with id: ${p.orgTeamTagId}`);
  }

  if (orgTeamTag.orgId !== p.orgId) {
    throw new Error(`The orgId ${p.orgId} does not match the orgId on the orgTeamTag ${p.orgTeamTagId}`);
  }

  if (p.newTag) {
    batchTasks.push(
      await h.OrgTeamTag.update(
        {
          id: p.orgTeamTagId,
          doc: {
            tag: p.newTag,
            updatedAtMS: Date.now()
          }
        },
        {
          returnBatchTask: true
        }
      )
    );
  }

  for (let i = 0; i < p.teamIdsToAdd.length; i++) {
    const teamId = p.teamIdsToAdd[i];
    batchTasks.push(
      await h.Team.update(
        {
          id: teamId,
          doc: {
            assignedOrgTagIds: {
              [p.orgTeamTagId]: true
            }
          }
        },
        { returnBatchTask: true }
      )
    );
  }

  for (let j = 0; j < p.teamIdsToRemove.length; j++) {
    const teamId = p.teamIdsToRemove[j];
    batchTasks.push(
      await h.Team.update(
        {
          id: teamId,
          doc: {
            assignedOrgTagIds: {
              [p.orgTeamTagId]: h._MagicDeleteValue
            }
          }
        },
        { returnBatchTask: true }
      )
    );
  }

  await Promise.all(
    _.chunk(batchTasks, 500).map(async chunk => {
      return await h._BatchRunner.executeBatch(chunk);
    })
  );

  // SERVER_ONLY_TOGGLE
}

orgTeamTag__server__bulkUpdateTag.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};
// i18n certified - complete
